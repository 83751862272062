import Joi, { ValidationErrorItem } from 'joi'
import { Vue, Component, Prop } from 'vue-property-decorator'

interface MappedErrors {
  [key: string]: string;
}
@Component
export class Step extends Vue {
  @Prop({ default: true }) readonly sidebarShow!: boolean
  @Prop({ default: '/images/sidebar/default.png' }) readonly sidebarImg!: string
  @Prop({ default: '/images/sidebar/1x-Bricklayer.png' }) readonly sidebarAccountImg!: string
  @Prop({ default: '/images/sidebar/1x-Sailor.png' }) readonly sidebarCompanyImg!: string
  @Prop({ default: '/images/sidebar/1x-Plantbeingwatered.png' }) readonly sidebarBusinessImg!: string
  @Prop({ default: '/images/sidebar/1x-Piechart.png' }) readonly sidebarVerticalImg!: string
  @Prop({ default: '/images/sidebar/1x-Magicianshat.png' }) readonly sidebarSkillImg!: string

  created (): void {
    if (this.$store.getters.flowStarted) {
      this.preventLeavePage()
    }
  }

  preventLeavePage (): void {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
  }

  beforeUnloadHandler (event: any) {
    if (this.$store.state.currentPage.step === 7) {
      delete event.returnValue
    } else {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  async validate (schema: any, data: any): Promise<any> {
    const validationSchema = Joi.object(schema)
    try {
      await validationSchema.validateAsync(data, { abortEarly: false })
    } catch (error) {
      let errorMessages
      if (error !== undefined) {
        errorMessages = this.mapErrorType(error.details)
      }
      return { errors: errorMessages }
    }
  }

  mapErrorType (errorDetails: ValidationErrorItem[]): MappedErrors {
    let currentView = this.$store.state.currentPage.view
    currentView = currentView[0].toLowerCase() + currentView.slice(1)

    const errorMap: any = {
      'string.min': this.$t('general.errors.min'),
      'string.max': this.$t('general.errors.max'),
      'string.empty': this.$t('general.errors.empty'),
      'string.email': this.$t('general.errors.email'),
      'number.base': this.$t('general.errors.numberBase'),
      'string.length': this.$t(`steps.${currentView}.fields.errors.typeAndLength`),
      'string.pattern.base': this.$t(`steps.${currentView}.fields.errors.typeAndLength`),
      'email.unique': this.$t('general.errors.emailUnique'),
      'email.blacklisted': this.$t('general.errors.emailBlacklisted'),
      'username.unique': this.$t('general.errors.usernameUnique'),
      'string.min.password': this.$t(`steps.${currentView}.fields.errors.password.typeAndLength`),
      'string.pattern.base.password': this.$t(`steps.${currentView}.fields.errors.password.typeAndLength`)
    }

    const mappedErrors: MappedErrors = {}

    for (let i = 0, len = errorDetails.length; i !== len; i++) {
      const key: string = errorDetails[i].context?.key as string
      const translation = errorDetails[i].type + '.' + key
      const errorType = errorMap[translation] || errorMap[errorDetails[i].type] || 'Error'

      mappedErrors[key] = errorType
    }

    return mappedErrors
  }

  enableNextNavigation () {
    this.$store.dispatch('setNextStatus', true)
  }
}
